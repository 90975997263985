<template>
  <v-card :loading="submitting">
    <v-card-title>
      Request an Invite
    </v-card-title>
    <v-form
      v-if="!loading && waitlistOpen && !success"
      ref="form"
      @submit.prevent="requestInvite"
    >
      <v-card-text>
        <v-alert
          v-for="error in errorMessages"
          :key="error"
          type="error"
        >
          {{ error }}
        </v-alert>
        <p>Invitations to Quest are currently limited. To be added to the next available batch, enter your email address below.</p>
        <v-text-field
          v-model="email"
          :disabled="submitting"
          :rules="emailRules"
          required
          type="email"
          label="Email"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="submitting"
          type="submit"
        >
          Request Invite
        </v-btn>
      </v-card-actions>
    </v-form>
    <v-card-text v-else-if="success">
      <p>You are on the list!</p>
      <p>We appreciate your interest and can't wait to give you access to Quest's world of adventure.</p>
    </v-card-text>
    <v-card-text v-else-if="!waitlistOpen">
      <p>The waitlist is currently closed.</p>
    </v-card-text>
  </v-card>
</template>

<script>
import {allianceBaseURL} from '@/api'
import { parseResponseErrors } from '@/utils/errors'
import { emailRules } from '@/utils/rules'

export default {
  data() {
    return {
      loading: true,
      waitlistOpen: true,
      email : '',
      submitting: false,
      success: null,
      errorMessages: null,
      emailRules: emailRules,
    }
  },
  created: function () {
    this.loading = true
    this.$http.get(allianceBaseURL + '/alliance/registration-status/')
      .then((response) => {
        this.waitlistOpen = response.data.waitlist_open
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    requestInvite: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      this.success = null
      this.errorMessages = null
      const data = {
        email: this.email,
      }
      return this.$http.post(allianceBaseURL + '/alliance/recruit/add/', data)
        .then(() => {
          this.success = true
          this.email = ''
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          // If they're already on the list, show them the normal success message.
          let dupeIndex = this.errorMessages.indexOf('recruit with this email already exists.')
          if (dupeIndex > -1) {
            this.success = true
          } else {
            this.success = false
          }
        })
        .finally(() => this.submitting = false)
    }
  },
}
</script>
